// TODO talvez seja melhor uma pasta routes, com um arquivo para cada modulo e um index que exporta um array de rotas completo
import { TipoImportacaoEnum } from "@/core/enums/importacoes";

/**
 * @typedef {Object} Route
 * @property {Promise<any>} component
 * @property {string} path
 * @property {string} name
 * @property {boolean?} meta.public
 * @property {string} meta.pageTitle
 * @property {string | string[]} meta.permissions
 * @property {string | string[]} meta.userTypes
 * @property {'index' | 'create' | 'update' | 'delete'} meta.mode
 */

/** @type {Route[]} */
const base = [
  {
    path: "*",
    redirect: { name: "homepage" },
    name: "not-found"
  },
  {
    components: { public: () => import("@/templates/login.vue") },
    path: "/login",
    name: "login",
    meta: {
      public: true,
      pageTitle: "Login"
    }
  },
  {
    components: { public: () => import("@/templates/login-oauth.vue") },
    path: "/login/oauth/:id",
    name: "oauth",
    meta: {
      public: true,
      pageTitle: "Login OAuth2"
    }
  },
  {
    components: { public: () => import("@/templates/redefinirsenha/redefinir-senha.vue") },
    path: "/esqueci",
    name: "esqueci-senha",
    meta: {
      public: true,
      pageTitle: "Esqueci minha senha"
    }
  },
  {
    components: { public: () => import("@/templates/redefinirsenha/troca-senha.vue") },
    path: "/recuperar-senha/:token",
    name: "nova-senha",
    meta: {
      public: true,
      pageTitle: "Criar nova senha"
    }
  },
  {
    component: () => import("@/templates/home/home.vue"),
    path: "/home",
    name: "homepage",
    meta: {
      pageTitle: "Home"
    }
  },
  {
    component: () => import("@/templates/usuario/meu-perfil.vue"),
    path: "/perfil",
    name: "meu-perfil",
    meta: {
      pageTitle: "Meu Perfil"
    }
  },
  {
    components: {
      public: () => import("./templates/terms-of-use.vue"),
      default: () => import("./templates/terms-of-use.vue"),
    },
    path: "/termos-de-uso",
    name: "termos-de-uso",
    meta: {
      public: true,
      pageTitle: "Termos de Uso",
    },
  },
];

/** @type {Route[]} */
const galapos = [
  {
    component: () => import("@/templates/timesheet/alocar-horas-galapos.vue"),
    path: "/timesheet/galapos/alocar-horas",
    name: "alocar-horas-galapos",
    meta: {
      pageTitle: "Alocar Horas — Galapos"
    }
  },
  {
    // Apenas administradores devem conseguir acessar essa URL
    component: () => import("@/templates/util/endpoint_tester.vue"),
    path: "/endpoint-tester",
    name: "endpoint-tester",
    meta: {
      pageTitle: "Endpoint Tester",
      permissions: [],
    }
  }
];

/** @type {Route[]} */
const cadastros = [
  {
    path: "/cadastro",
    name: "listagem-empresas",
    component: () => import("@/templates/cadastro/clientes.vue"),
    meta: {
      pageTitle: "Clientes",
      permissions: ["Cadastro.clientes.index"],
    }
  },
  {
    component: () => import("@/templates/cadastro/clientes.form.vue"),
    path: "/cadastro/clientes/novo",
    name: "cadastro-empresa",
    meta: {
      pageTitle: "Cadastrar Cliente",
      permissions: ["Cadastro.clientes.create"],
      mode: "create",
      parentPage: "listagem-empresas",
    }
  },
  {
    component: () => import("@/templates/cadastro/clientes.form.vue"),
    path: "/cadastro/clientes/:empresaId(\\d+)",
    name: "edicao-empresa",
    meta: {
      pageTitle: "Editar Cliente",
      permissions: ["Cadastro.clientes.update"],
      mode: "update",
      parentPage: "listagem-empresas",
    }
  },
  {
    component: () => import("@/templates/cadastro/clientes.form.vue"),
    path: "/minha-conta",
    name: "minha-conta",
    meta: {
      pageTitle: "Dados da Empresa",
      permissions: ["Cadastro.clientes.update"],
      mode: "update",
    },
    // beforeEnter: (to, from) => {
    //   // TODO redirecionar a rota para edicao-empresa dependendo do tipo de usuário
    // }
  },
  {
    component: () => import("@/templates/cadastro/grupos-de-empresas.vue"),
    path: "/cadastro/corporacoes",
    name: "listagem-grupos-de-empresas",
    meta: {
      pageTitle: "Grupos de Empresas",
      permissions: ["Cadastro.grupoDeEmpresas.index"],
    },
  },
  {
    path: "/cadastro/consultores",
    name: "listagem-consultores",
    component: () => import("@/templates/cadastro/consultores.vue"),
    meta: {
      pageTitle: "Consultores",
      permissions: ["Cadastro.consultores.index"],
    },
  },
  {
    component: () => import("@/templates/cadastro/cliente.unidades.vue"),
    path: "/unidades",
    name: "listagem-unidades",
    meta: {
      pageTitle: "Unidades",
      permissions: ["Cadastro.clientes.create"],
    },
  },
  {
    component: () => import("@/templates/controleacesso/usuarios.vue"),
    path: "/cadastro/controle-de-acesso",
    name: "listagem-usuarios",
    meta: {
      pageTitle: "Usuários do Sistema",
      permissions: ["Cadastro.usuarios.index"],
    },
  },
  {
    component: () => import("@/templates/controleacesso/usuarios.form.vue"),
    path: "/cadastro/controle-de-acesso/novo",
    name: "cadastro-usuario",
    meta: {
      pageTitle: "Cadastrar Usuário do Sistema",
      permissions: ["Cadastro.usuarios.create"],
      mode: "create",
      parentPage: "listagem-usuarios",
    }
  },
  {
    component: () => import("@/templates/controleacesso/usuarios.form.vue"),
    path: "/cadastro/controle-de-acesso/:id(\\d+)",
    name: "edicao-usuario",
    meta: {
      pageTitle: "Editar Usuário do Sistema",
      permissions: ["Cadastro.usuarios.create"],
      // permissions: ["Cadastro.usuarios.update"],
      mode: "update",
      parentPage: "listagem-usuarios",
    }
  },
  {
    component: () => import("@/templates/cadastro/csll.vue"),
    path: "/cadastro/csll/:id(\\d+)",
    name: "cadastro-csll",
    meta: {
      pageTitle: "Alíquota CSLL",
      permissions: ["Cadastro.usuarios.create"], // TODO validar depois a permissao correta
      // permissions: ["Cadastro.usuarios.update"],
    }
  },
  {
    component: () => import("@/templates/cadastro/credenciais.vue"),
    path: "/cadastro/credenciais",
    name: "listagem-credenciais",
    meta: {
      pageTitle: "Credenciais",
      permissions: ["Cadastro.RPA"],
      mode: "update"
    }
  },
  {
    component: () => import("@/templates/cadastro/credenciais.form.vue"),
    path: "/cadastro/credenciais-cadastro",
    name: "cadastro-credenciais",
    meta: {
      pageTitle: "Cadastro de credenciais",
      permissions: ["Cadastro.RPA.create"],
      mode: "create"
    }
  },
  {
    component: () => import("@/templates/cadastro/credenciais.form.vue"),
    path: "/cadastro/credenciais-cadastro/:id(\\d+)",
    name: "edicao-credenciais",
    meta: {
      pageTitle: "Edição de credenciais",
      permissions: ["Cadastro.RPA.update"],
      mode: "update"
    }
  },
  {
    component: () => import("@/templates/cadastro/regularidade-fiscal.vue"),
    path: "/cadastro/regularidade-fiscal",
    name: "listagem-certidoes",
    meta: {
      pageTitle: "Regularidade Fiscal",
      permissions: ["Cadastro.clientes.create"],
    }
  },
];

/** @type {Route[]} */
const relatorios = [
  {
    component: () => import("@/templates/dashboard/dashboardgeral.vue"),
    path: "/dashboard/dashboardgeral",
    name: "dashboard-geral",
    meta: {
      pageTitle: "Dashboard Geral",
      permissions: ["Dashboard.resource.geral"],
    }
  },
  {
    component: () => import("@/templates/dashboard/inconsistencias.vue"),
    path: "/dashboard/inconsistencias",
    name: "inconsistencias",
    meta: {
      pageTitle: "Status e Inconsistências",
      permissions: ["Dashboard.resource.status-e-inconsistencias"]
    }
  },
  {
    component: () => import("@/templates/dashboard/exportacaorelatorios.vue"),
    path: "/clientes/dashboard/relatorios-exportados",
    name: "listagem-relatorios-assincronos",
    meta: {
      pageTitle: "Relatórios Exportados",
      // Sem permissão, qualquer um pode acessar
    }
  },
  {
    component: () => import("@/templates/dashboard/resumoped.vue"),
    path: "/dashboard/resumoped",
    name: "resumo-ped",
    meta: {
      pageTitle: "Resumo de P&D",
      permissions: ["Dashboard.dispendios.resumoDePED"]
    }
  },
  {
    component: () => import("@/templates/dashboard/valorincentivo.vue"),
    path: "/dashboard/valorincentivo",
    name: "valor-incentivado",
    meta: {
      pageTitle: "Valor Aproveitado de Incentivo",
      permissions: ["Dashboard.dispendios.valorAproveitadoIncentivo"]
    }
  },
  {
    component: () =>
      import("@/templates/dashboard/servicosterceirosdetalhado.vue"),
    path: "/dashboard/servicosterceirosdetalhado",
    name: "servicos-terceiros-detalhados",
    meta: {
      pageTitle: "Serviços Terceiros Detalhados",
      permissions: ["Dashboard.dispendios.servicosTerceirosDetalhados"]
    }
  },
  {
    component: () => import("@/templates/dashboard/materiaisdetalhado.vue"),
    path: "/dashboard/materiaisdetalhado",
    name: "materiais-detalhados",
    meta: {
      pageTitle: "Materiais Detalhados",
      permissions: ["Dashboard.dispendios.materiaisDetalhado"]
    }
  },
  {
    component: () => import("@/templates/dashboard/resumogastosprojeto.vue"),
    path: "/dashboard/resumogastosprojeto",
    name: "resumo-gastos-por-projeto",
    meta: {
      pageTitle: "Resumo Gastos Projeto",
      permissions: ["Dashboard.dispendios.resumoDeGastosPorProjetos"]
    }
  },
  {
    component: () => import("@/templates/dashboard/resumorhprojeto.vue"),
    path: "/dashboard/resumorhprojeto",
    name: "resumo-rh-por-projeto",
    meta: {
      pageTitle: "Resumo RH Projeto",
      permissions: ["Dashboard.dispendios.resumoRHProjeto"]
    }
  },
  {
    component: () => import("@/templates/dashboard/resumorhagrupamento.vue"),
    path: "/dashboard/resumorhagrupamento",
    name: "resumo-rh-por-agrupamento",
    meta: {
      pageTitle: "Resumo RH Agrupamento",
      permissions: ["Dashboard.dispendios.resumoRHProjeto"]
    }
  },
  {
    component: () => import("@/templates/dashboard/gastosterceiros.vue"),
    path: "/dashboard/gastosterceiros",
    name: "gastos-terceiros",
    meta: {
      pageTitle: "Gastos com Terceiros",
      permissions: ["Dashboard.dispendios.gastosComTerceiros"]
    }
  },
  {
    component: () => import("@/templates/dashboard/gastosmateriais.vue"),
    path: "/dashboard/gastosmateriais",
    name: "gastos-materiais",
    meta: {
      pageTitle: "Gastos com Materiais",
      permissions: ["Dashboard.dispendios.gastosComMateriais"]
    }
  },
  {
    component: () => import("@/templates/dashboard/calculopesquisadores.vue"),
    path: "/dashboard/calculopesquisadores",
    name: "calculo-pesquisadores",
    meta: {
      pageTitle: "Cálculo Incremento",
      permissions: ["Dashboard.pesquisadores.incremento"]
    }
  },
  {
    component: () =>
      import("@/templates/dashboard/calculopesquisadoresdetalhado.vue"),
    path: "/dashboard/calculopesquisadoresdetalhado",
    name: "calculo-pesquisadores-detalhado",
    meta: {
      pageTitle: "Cálc. Incremento Detalhado",
      permissions: ["Dashboard.pesquisadores.incrementoDetalhado"]
    }
  },
  {
    component: () => import("@/templates/dashboard/horasprojeto.vue"),
    path: "/dashboard/horaslogadasprojeto",
    name: "horas-logadas-por-projeto",
    meta: {
      pageTitle: "Horas Logadas por Projeto",
      permissions: ["Dashboard.logDeHoras.porProjeto"]
    }
  },
  {
    component: () => import("@/templates/dashboard/horascolaborador.vue"),
    path: "/dashboard/loghorascolaborador",
    name: "horas-logadas-por-colaborador",
    meta: {
      pageTitle: "Horas Logadas por Projeto",
      permissions: ["Dashboard.logDeHoras.porColaborador"]
    }
  },
  {
    component: () => import("@/templates/dashboard/resumogeralli.vue"),
    path: "/dashboard/resumogeralli",
    name: "resumo-geral-li",
    meta: {
      pageTitle: "Resumo do Faturamento Incentivado",
      permissions: ["Dashboard.faturamentoIncentivado.resumoDoFaturamentoIncentivado"]
    }
  },
  {
    component: () =>
      import("@/templates/faturamento/relatorio-dispendio-rda.vue"),
    path: "/dashboard/relatoriodispendio",
    name: "relatorio-dispendios",
    meta: {
      pageTitle: "Dispêndio de LI para RDA",
      permissions: ["Dashboard.faturamentoIncentivado.resumoDePEDLIParaRDA"]
    }
  },

  {
    component: () => import("@/templates/dashboard/dedicacoes.vue"),
    path: "/dashboard/colaboradores/dedicacoes",
    name: "relatorio-dedicacoes",
    meta: {
      pageTitle: "Dedicações",
      permissions: ["Dashboard.colaboradores.dedicacao"]
    }
  },
  {
    component: () => import("@/templates/dashboard/unidades.vue"),
    path: "/dashboard/colaboradores/unidades",
    name: "relatorio-unidades",
    meta: {
      pageTitle: "Unidades",
      permissions: ["Dashboard.colaboradores.unidades"]
    }
  },
  {
    component: () => import("@/templates/dashboard/gruposdeencargos.vue"),
    path: "/dashboard/colaboradores/encargos",
    name: "relatorio-grupos-encargos",
    meta: {
      pageTitle: "Grupos de Encargos",
      permissions: ["Dashboard.colaboradores.grupoDeEncargos"]
    }
  },
  {
    component: () =>
      import("@/templates/dashboard/colaboradores.cliente.tiporh.vue"),
    path: "/dashboard/colaboradores/tiporh",
    name: "relatorio-tipo-rh",
    meta: {
      pageTitle: "Tipo RH",
      permissions: ["Dashboard.colaboradores.tipoRH"]
    }
  },
  {
    component: () =>
      import("@/templates/dashboard/consumomateriaisdetalhado.vue"),
    path: "/dashboard/consumomateriaisdetalhado",
    name: "consumo-materiais-detalhados",
    meta: {
      pageTitle: "Consumo de Materiais Detalhado",
      permissions: ["Dashboard.dispendios.consumoDetalhado"],
    },
  },
  {
    component: () => import("@/templates/dashboard/cumprimentoobrigacao.vue"),
    path: "/dashboard/cumprimentoobrigacao",
    name: "cumprimento-obrigacao",
    meta: {
      pageTitle: "Cumprimento de Obrigação de P&D",
      permissions: ["Dashboard.faturamentoIncentivado.cumprimentoDeObrigacaoDePED"],  // TODO
    },
  },
  {
    component: () => import("@/templates/dashboard/resumocreditofinanceiro.vue"),
    path: "/dashboard/resumocreditofinanceiro",
    name: "resumo-credito-financeiro-trimestral",
    meta: {
      pageTitle: "Resumo de Crédito Financeiro Trimestral",
      permissions: ["Dashboard.faturamentoIncentivado.resumoCreditoFinanceiroTrimestral"],  // TODO
    },
  },
];

/** @type {Route[]} */
const colaboradores = [
  {
    component: () => import("@/templates/colaboradores/colaboradores.vue"),
    path: "/recursoshumanos",
    name: "listagem-colaboradores",
    meta: {
      pageTitle: "Colaboradores",
      permissions: ["Colaboradores.resource.index"],
    },
  },
  {
    component: () => import("@/templates/colaboradores/colaboradores.form.vue"),
    path: "/recursoshumanos/colaborador/novo",
    name: "cadastro-colaborador",
    meta: {
      pageTitle: "Cadastro de Colaborador",
      permissions: ["Colaboradores.resource.create"],
      mode: "create",
      parentPage: "listagem-colaboradores",
    }
  },
  {
    component: () => import("@/templates/colaboradores/colaboradores.form.vue"),
    path:
      "/recursoshumanos/colaborador/:colaboradorId(\\d+)",
    name: "edicao-colaborador",
    meta: {
      pageTitle: "Edição de Colaborador",
      permissions: ["Colaboradores.resource.create"],
      mode: "update",
      parentPage: "listagem-colaboradores",
    }
  },
  {
    component: () =>
      import("@/templates/colaboradores/colaboradores.depara.vue"),
    path:
      "/recursoshumanos/colaborador/:colaboradorId(\\d+)/depara",
    name: "de-para-colaboradores",
    meta: {
      pageTitle: "De-para de Colaboradores",
      permissions: ["Colaboradores.resource.depara"],
      parentPage: "listagem-colaboradores",
    }
  },
  {
    component: () =>
      import("@/templates/colaboradores/colaboradores.fopag.vue"),
    path:
      "/recursoshumanos/colaborador/:colaboradorId(\\d+)/fopag",
    name: "folha-pagamento-colaborador",
    meta: {
      pageTitle: "FOPAGs",
      permissions: ["Colaboradores.folhaDePagamento.index"],
      parentPage: "listagem-colaboradores",
    }
  },
  {
    component: () =>
      import("@/templates/colaboradores/colaboradores.fopag.item.vue"),
    path:
      "/recursoshumanos/colaborador/:colaboradorId(\\d+)/fopag/:fopagId(\\d+)/itens",
    name: "itens-folha-pagamento-colaborador",
    meta: {
      pageTitle: "Rubricas da FoPag",
      permissions: ["Colaboradores.folhaDePagamento.index"],
      parentPage: "listagem-colaboradores",
    }
  }
];

/** @type {Route[]} */
const projetos = [
  {
    component: () => import("@/templates/projetos/projetos.analise.vue"),
    path: "/projetos",
    name: "listagem-projetos-analise",
    meta: {
      pageTitle: "Análise",
      permissions: ["Projetos.analise.index"],
    }
  },
  {
    component: () => import("@/templates/projetos/projetos.analise.form.vue"),
    path: "/projetos/novo",
    name: "cadastro-projeto",
    meta: {
      pageTitle: "Cadastro de Projeto",
      permissions: ["Projetos.analise.create"],
      mode: "create",
      parentPage: "listagem-projetos-analise",
    }
  },
  {
    component: () => import("@/templates/projetos/projetos.analise.form.vue"),
    path: "/projetos/:projetoId(\\d+)/analise",
    name: "ficha-projeto-analise",
    meta: {
      pageTitle: "Ficha de Projeto em Análise",
      permissions: ["Projetos.analise.create"],
      mode: "update",
      parentPage: "listagem-projetos-analise",
    }
  },
  {
    component: () => import("@/templates/projetos/projetos.analise.vue"),
    path: "/projetos/inclusao",
    name: "listagem-inclusao",
    meta: {
      pageTitle: "Análise",
      permissions: ["Projetos.analise.index"],
    },
  },
  {
    component: () => import("@/templates/projetos/projetos.analise.form.vue"),
    path: "/projetos/inclusao/novo",
    name: "inclusao-projeto",
    meta: {
      pageTitle: "Cadastro de Projeto",
      permissions: ["Projetos.analise.create"],
      mode: "create",
      parentPage: "listagem-inclusao",
    },
  },
  {
    component: () => import("@/templates/projetos/projetos.analise.form.vue"),
    path: "/projetos/inclusao/:projetoId(\\d+)",
    name: "edicao-inclusao-projeto",
    meta: {
      pageTitle: "Edição de Projeto",
      permissions: ["Projetos.analise.create"],
      mode: "update",
      parentPage: "listagem-inclusao",
    },
  },
  {
    component: () => import("@/templates/projetos/projetos.enquadrados.vue"),
    path: "/projetos/enquadrados",
    name: "listagem-projetos-enquadrados",
    meta: {
      pageTitle: "Enquadrados",
      permissions: ["Projetos.enquadrados.index"]
    }
  },
  {
    component: () =>
      import("@/templates/projetos/projetos.enquadrados.form.vue"),
    path: "/projetos/enquadrados/:projetoId(\\d+)",
    name: "ficha-projeto-enquadrado",
    meta: {
      pageTitle: "Ficha de Projeto Enquadrado",
      permissions: ["Projetos.enquadrados.update"],
      parentPage: "listagem-projetos-enquadrados",
    }
  },
  {
    component: () => import("@/templates/projetos/rastreio.vue"),
    path: "/projetos/:projetoId(\\d+)/historico",
    name: "rastreio-projeto",
    meta: {
      pageTitle: "Histórico de Alterações",
      permissions: ["Projetos.enquadrados.update"],  // TODO por a permissão correta
    },
  },
  {
    component: () => import("@/templates/projetos/justificativas.vue"),
    path: "/projetos/justificativa",
    name: "listagem-projetos-justificativa",
    meta: {
      pageTitle: "Justificativas de dispêndios",
      permissions: ["Projetos.enquadrados.update"],
    }
  },
];

/** @type {Route[]} */
const dispendios = [
  {
    component: () => import("@/templates/dispendio/calculo.vue"),
    path: "/dispendio",
    name: "dispendio-rh",
    meta: {
      pageTitle: "Dispêndio com Recursos Humanos",
      permissions: ["Dispendios.recursosHumanos.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/materiais.vue"),
    path: "/dispendio/materiais",
    name: "dispendio-materiais",
    meta: {
      pageTitle: "Dispêndio com Materiais",
      permissions: ["Dispendios.materiais.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/operacional.vue"),
    path: "/dispendio/servicoterceiros",
    name: "dispendio-servicos-terceiros",
    meta: {
      pageTitle: "Dispêndio com Serviço de Terceiros",
      permissions: ["Dispendios.servicosTerceiros.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/equipamentos-e-softwares.vue"),
    path: "/dispendio/equipamentos-e-softwares",
    name: "listagem-dispendio-equipamentos-softwares",
    meta: {
      pageTitle: "Dispêndio com Equipamentos e Softwares",
      permissions: ["Dispendios.equipamentosSoftwares.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/equipamentos-e-softwares.form.vue"),
    path: "/dispendio/equipamentos-e-softwares/novo",
    name: "cadastro-dispendio-equipamentos-softwares",
    meta: {
      pageTitle: "Cadastro de NF de Equipamentos e Softwares",
      permissions: ["Dispendios.equipamentosSoftwares.create"],
      mode: "create",
      parentPage: "listagem-dispendio-equipamentos-softwares",
    }
  },
  {
    component: () => import("@/templates/dispendio/equipamentos-e-softwares.form.vue"),
    path: "/dispendio/equipamentos-e-softwares/:nfId(\\d+)",
    name: "edicao-dispendio-equipamentos-softwares",
    meta: {
      pageTitle: "Edição de NF de Equipamentos e Softwares",
      permissions: ["Dispendios.equipamentosSoftwares.update"],
      mode: "update",
      parentPage: "listagem-dispendio-equipamentos-softwares",
    }
  },
  {
    component: () => import("@/templates/dispendio/materiaisimportados.vue"),
    path: "/dispendio/consumomateriais",
    name: "dispendio-consumo-materiais",
    meta: {
      pageTitle: "Dispêndio com Consumo de Materiais",
      permissions: ["Dispendios.consumoMateriais.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/destaques.sistema.vue"),
    path: "/dispendio/destaques",
    name: "rubricas-padrao-sistema",
    meta: {
      pageTitle: "Padrão do Sistema",
      permissions: ["Dispendios.rubricasPadrao.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/destaques.parear.vue"),
    path: "/dispendio/definicao",
    name: "pareamento-rubricas",
    meta: {
      pageTitle: "Pareamento de Rubricas",
      permissions: ["Dispendios.pareamentoRubricas.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/catalogo-rubricas-cliente.vue"),
    path: "/dispendio/catalogo",
    name: "catalogo-rubricas",
    meta: {
      pageTitle: "Catálogo de Rubricas",
      permissions: ["Dispendios.pareamentoRubricas.index"], // TODO ver se tem permissão própria ou não
    }
  },
  {
    component: () => import("@/templates/dispendio/grupoencargos.vue"),
    path: "/dispendio/grupoencargos",
    name: "dispendio-encargos-previdenciarios",
    meta: {
      pageTitle: "Grupo Encargos Previdenciários",
      permissions: ["Dispendios.grupoEncargos.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/encargos.vue"),
    path: "/dispendio/grupoencargos/:grupoId(\\d+)/encargos",
    name: "dispendio-encargos-previdenciarios-detalhes",
    meta: {
      pageTitle: "Encargos Previdenciários",
      permissions: ["Dispendios.grupoEncargos.index"],
      parentPage: "dispendio-encargos-previdenciarios",
    }
  },
  {
    component: () => import("@/templates/dispendio/terceiros.vue"),
    path: "/dispendio/terceiros",
    name: "dispendio-fornecedores-terceiros",
    meta: {
      pageTitle: "Cadastro de Fornecedores e Terceiros",
      permissions: ["Dispendios.fornecedoresTerceiros.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/materiais.items.vue"),
    path: "/dispendio/materiais/fiscal/nfm/:nfId(\\d+)",
    name: "dispendio-materiais-detalhes",
    meta: {
      pageTitle: "NF de Materiais - Itens",
      permissions: ["Dispendios.materiais.index"],
      parentPage: "dispendio-materiais",
    }
  },
  {
    component: () => import("@/templates/dispendio/operacional.items.vue"),
    path: "/dispendio/servicoterceiros/fiscal/nft/:nfId(\\d+)",
    name: "dispendio-servicos-terceiros-detalhes",
    meta: {
      pageTitle: "NFs Serviço de Terceiros - Itens",
      permissions: ["Dispendios.servicosTerceiros.index"],
      parentPage: "dispendio-servicos-terceiros",
    }
  },
  {
    component: () => import("@/templates/dispendio/lalur.vue"),
    path: "/dispendio/lalur",
    name: "lalur",
    meta: {
      pageTitle: "LALUR",
      permissions: ["Dispendios.lalur.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/dirbi.vue"),
    path: "/dispendio/dirbi",
    name: "dirbi",
    meta: {
      pageTitle: "DIRBI",
      permissions: ["Dispendios.lalur.index"],  // TODO trocar pela permissão correta
      // permissions: ["Dispendios.dirbi.index"],
    }
  },
  {
    component: () => import("@/templates/dispendio/ecf.vue"),
    path: "/dispendio/ecf",
    name: "ecf",
    meta: {
      pageTitle: "ECF",
      permissions: ["Dispendios.lalur.index"],  // TODO trocar pela permissão correta
      // permissions: ["Dispendios.ecf.index"],
    }
  },
  {
    component: () => import("@/templates/projetoexterno/projetoexterno.vue"),
    path: "/dispendio/projetoexterno",
    name: "listagem-projetos-externos",
    meta: {
      pageTitle: "Projetos Externos",
      permissions: ["Faturamento.resource.geral"] // TODO
    }
  },
  {
    component: () => import("@/templates/projetoexterno/projetoexterno.vue"),
    path: "/dispendio/projetoexterno/projetos",
    name: "cadastro-projeto-externo",
    meta: {
      pageTitle: "Cadastro de Projetos Externos",
      permissions: ["Faturamento.resource.geral"], // TODO
      parentPage: "listagem-projetos-externos",
    }
  },
  {
    component: () =>
      import("@/templates/projetoexterno/projetoexterno.items.vue"),
    path: "/dispendio/projetoexterno/projetos/:pId(\\d+)",
    name: "projeto-externo-detalhes",
    meta: {
      pageTitle: "Projeto Externo - Itens",
      permissions: ["Faturamento.resource.geral"], // TODO
      parentPage: "listagem-projetos-externos",
    }
  },
  {
    component: () => import("@/templates/projetoexterno/instituicao.vue"),
    path: "/dispendio/projetoexterno/instituicao",
    name: "cadastro-instituicao",
    meta: {
      pageTitle: "Cadastro de Instituições",
      permissions: ["Faturamento.resource.geral"] // TODO
    }
  },
  {
    component: () => import("@/templates/projetoexterno/controlegastos.vue"),
    path: "/dispendio/projetoexterno/controlegastos/:projetoExternoId(\\d+)",
    name: "controle-gastos",
    meta: {
      pageTitle: "Controle de Gastos",
      permissions: ["Faturamento.resource.geral"], // TODO
      parentPage: "listagem-projetos-externos",
    }
  },
  {
    component: () => import("@/templates/projetoexterno/tipo.boleto.vue"),
    path: "/dispendio/projetoexterno/tipoboleto",
    name: "tipo-boleto",
    meta: {
      pageTitle: "Tipo Boleto",
      permissions: ["Faturamento.resource.geral"] // TODO
    }
  },
  {
    component: () => import("@/templates/projetoexterno/tipo.convenio.vue"),
    path: "/dispendio/projetoexterno/tipoconvenio",
    name: "tipo-convenio",
    meta: {
      pageTitle: "Tipo Convênio",
      permissions: ["Faturamento.resource.geral"] // TODO
    }
  }
];

/** @type {Route[]} */
const timesheets = [
  {
    component: () => import("@/templates/timesheet/timesheets.vue"),
    path: "/timesheet",
    name: "timesheets",
    meta: {
      pageTitle: "Alocação de Horas",
      permissions: ["Timesheet.alocacaoDeHoras.index"]
    }
  },
  {
    component: () => import("@/templates/timesheet/alocar-horas.vue"),
    path: "/timesheet/alocar-horas",
    name: "listagem-alocar-horas",
    meta: {
      pageTitle: "Alocação de Horas",
      permissions: ["Timesheet.alocacaoDeHoras.index"],
    },
  },
  {
    component: () => import("@/templates/timesheet/atividades.vue"),
    path: "/timesheet/atividades",
    name: "atividades",
    meta: {
      pageTitle: "Etapas / Atividades",
      permissions: ["Timesheet.etapasAtividades.index"],
    }
  },
  {
    component: () =>
      import("@/templates/timesheet/colaboradoresprojetomensal.vue"),
    path: "/timesheet/colaboradoresprojeto",
    name: "colaboradores-por-projeto",
    meta: {
      pageTitle: "Colaboradores por Projeto",
      permissions: ["Timesheet.colaboradoresPorProjetos.index"],
    }
  },
  {
    component: () =>
      import("@/templates/timesheet/conferencia-mensal/conferencia-mensal.vue"),
    path: "/timesheet/conferenciamensal",
    name: "conferencia-mensal",
    meta: {
      pageTitle: "Conferência Mensal",
      permissions: ["Timesheet.colaboradoresPorProjetos.index"],
    }
  },
];

/** @type {Route[]} */
const faturamentos = [
  {
    component: () => import("@/templates/faturamento/produtos.calculo.vue"),
    path: "/faturamento/produtoscalculo",
    name: "listagem-produtos-incentivados",
    meta: {
      pageTitle: "Cadastro de Produtos Incentivados",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/modelosincentivados.vue"),
    path:
      "/faturamento/modelosincentivados/:codigoProduto(\\d+)",
    name: "listagem-modelos-incentivados",
    meta: {
      pageTitle: "Cadastro de Modelos Incentivados",
      permissions: ["Faturamento.resource.geral"],  // TODO
      parentPage: "listagem-produtos-incentivados",
    }
  },
  {
    component: () => import("@/templates/faturamento/aliquotaadicional.vue"),
    path:
      "/faturamento/aliquotaadicional/:codigoProduto(\\d+)",
    name: "listagem-aliquota-adicional",
    meta: {
      pageTitle: "Cadastro de Alíquota Adicional",
      permissions: ["Faturamento.resource.geral"],  // TODO
      parentPage: "listagem-produtos-incentivados",
    }
  },
  {
    component: () => import("@/templates/faturamento/produto.ncm.vue"),
    path: "/faturamento/produtoncm/:codigoProduto(\\d+)",
    name: "listagem-ncm",
    meta: {
      pageTitle: "Cadastro de NCM",
      permissions: ["Faturamento.resource.geral"],  // TODO
      parentPage: "listagem-produtos-incentivados",
    }
  },
  {
    component: () => import("@/templates/faturamento/catalogo.vue"),
    path: "/faturamento/catalogo",
    name: "catalogo-itens",
    meta: {
      pageTitle: "Catálogo de Itens",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/nfegeral.vue"),
    path: "/faturamento/notas/geral",
    name: "listagem-notas-fiscais",
    meta: {
      pageTitle: "Notas Fiscais",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/nfegeral.items.vue"),
    path: "/faturamento/notas/geral/:nfId(\\d+)",
    name: "listagem-itens-nota-fiscal",
    meta: {
      pageTitle: "Itens de Nota Fiscal",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/sequencianfegeral.vue"),
    path: "/faturamento/notas/sequencia",
    name: "listagem-sequencia-notas-fiscais",
    meta: {
      pageTitle: "Sequência de Notas Fiscais",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/devolucao.vue"),
    path: "/faturamento/notas/devolucao",
    name: "listagem-notas-fiscais-devolucao",
    meta: {
      pageTitle: "Notas Fiscais de Devolução",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/devolucao_origem.vue"),
    path: "/faturamento/notas/devolucao/:nfId(\\d+)",
    name: "listagem-notas-fiscais-devolucao-origem",
    meta: {
      pageTitle: "Busca Origem Devolução",
      permissions: ["Faturamento.resource.geral"],  // TODO
    },
    props: route => {
      return {
        selectedNF: route.params.selectedNF
      };
    }
  },
  {
    component: () => import("@/templates/faturamento/conciliacao.vue"),
    path: "/faturamento/notas/conciliacao",
    name: "conciliacao-devolucoes",
    meta: {
      pageTitle: "Conciliação de Devoluções Incentivadas",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/status.calculo.ped.vue"),
    path: "/faturamento/calculo/status",
    name: "status-faturamento-incentivado",
    meta: {
      pageTitle: "Status de Fat. Incentivado",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/calculo.ped.vue"),
    path: "/faturamento/calculo",
    name: "faturamento-produtos-incentivados",
    meta: {
      pageTitle: "Faturamento de Prod. Incentivados",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/sigplani_produtos.vue"),
    path: "/faturamento/sigplani/produtos",
    name: "sigplani-produtos",
    meta: {
      pageTitle: "Novo Sigplani Produtos",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/sigplani_resumo.vue"),
    path: "/faturamento/sigplani/resumo",
    name: "resumo-credito-financeiro",
    meta: {
      pageTitle: "Resumo Crédito Financeiro — Sigplani",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/cfop.vue"),
    path: "/faturamento/cfop",
    name: "listagem-cfops",
    meta: {
      pageTitle: "CFOPs",
      permissions: ["Faturamento.resource.geral"],  // TODO
    }
  },
  {
    component: () => import("@/templates/faturamento/faf.vue"),
    path: "/faturamento/faf",
    name: "faf",
    meta: {
      pageTitle: "FAF",
      permissions: ["Faturamento.resource.geral"], // TODO validar depois a permissao correta
      // permissions: ["Cadastro.faf.index"],
    }
  },
  {
    component: () => import("@/templates/faturamento/credito-presumido-resumo.vue"),
    path: "/faturamento/credito-presumido-resumo",
    name: "credito-presumido-resumo",
    meta: {
      pageTitle: "Crédito Presumido — Resumo",
      permissions: ["Faturamento.resource.geral"], // TODO validar depois a permissao correta
      // permissions: ["Cadastro.faf.index"],
    }
  },
  {
    component: () => import("@/templates/faturamento/nfe-presumido.vue"),
    path: "/faturamento/nfe-presumido",
    name: "nfe-presumido",
    meta: {
      pageTitle: "Notas Fiscais do Presumido",
      permissions: ["Faturamento.resource.geral"], // TODO validar depois a permissao correta
      // permissions: ["Cadastro.faf.index"],
    }
  },
];

/** @type {Route[]} */
const importacoes = [
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/importar",
    name: "listagem-importacoes",
    meta: {
      pageTitle: 'Importações',
      name: "Importações",
      permissions: ["Importacoes.resource.index"],
    }
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/recursoshumanos/folha-de-pagamento",
    name: "listagem-importacoes-fopag",
    meta: {
      pageTitle: "Importação de Folha de Pagamento",
      tipoImportacao: TipoImportacaoEnum.RH_FOPAG,
      permissions: ["Importacoes.resource.index", "Importacoes.recursosHumanos.folhaDePagamento"],
    },
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/recursoshumanos/provisoes",
    name: "listagem-importacoes-provisoes",
    meta: {
      pageTitle: "Importação de Provisões",
      tipoImportacao: TipoImportacaoEnum.RH_PROVISOES,
      permissions: ["Importacoes.resource.index", "Importacoes.recursosHumanos.provisoes"],
    },
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/recursoshumanos/controle-de-ponto",
    name: "listagem-importacoes-ponto",
    meta: {
      pageTitle: "Importação de Controle de Ponto",
      tipoImportacao: TipoImportacaoEnum.RH_PONTO,
      permissions: ["Importacoes.resource.index", "Importacoes.recursosHumanos.controleDePonto"],
    },
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/timesheet/importar",
    name: "listagem-importacoes-timesheet",
    meta: {
      pageTitle: "Importação de Timesheet",
      tipoImportacao: TipoImportacaoEnum.RH_TIMESHEET,
      permissions: ["Importacoes.resource.index", "Importacoes.timesheets.timesheet"],
    },
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/dispendio/servicoterceiros/importar/anexo",
    name: "listagem-importacoes-servicos-terceiros-anexo",
    meta: {
      pageTitle: "Anexar NF de Serviços Terceiros (PDF)",
      tipoImportacao: TipoImportacaoEnum.ANEXO_NF_SERVICOS_TERCEIROS,
      permissions: ["Importacoes.resource.index", "Importacoes.dispendios.anexosDeServicosDeTerceiros"],
    },
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/dispendio/servicoterceiros/importar/planilha",
    name: "listagem-importacoes-servicos-terceiros-planilha",
    meta: {
      pageTitle: "Importar Relatório de NF de Serviços Terceiros",
      tipoImportacao: TipoImportacaoEnum.FISCAL_NF_TERCEIROS,
      permissions: ["Importacoes.resource.index", "Importacoes.dispendios.planilhaDeTerceiros"],
    },
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/materiais/importar/anexo",
    name: "listagem-importacoes-materiais-anexo",
    meta: {
      pageTitle: "Anexar NF de Materiais (PDF)",
      tipoImportacao: TipoImportacaoEnum.ANEXO_NF_MATERIAIS,
      permissions: ["Importacoes.resource.index", "Importacoes.dispendios.anexosDeMateriais"],
    },
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/materiais/importar/planilha",
    name: "listagem-importacoes-materiais",
    meta: {
      pageTitle: "Importar Relatório de NF de Materiais",
      tipoImportacao: TipoImportacaoEnum.FISCAL_NF_MATERIAIS,
      permissions: ["Importacoes.resource.index", "Importacoes.dispendios.planilhaDeMateriais"],
    },
  },
  {
    component: () => import("@/templates/importar/importacao.vue"),
    path: "/dispendio/baixadeestoque/importar/planilha",
    name: "listagem-importacoes-consumo-materiais",
    meta: {
      pageTitle: "Importar relatório de consumo de materiais",
      tipoImportacao: TipoImportacaoEnum.FISCAL_NF_CONSUMO_MATERIAIS,
      permissions: ["Importacoes.resource.index", "Importacoes.dispendios.planilhaDeConsumoDeMateriais"],
    },
  },
  {
    component: () => import("./templates/importar/importacao.vue"),
    path: "/faturamento/importar",
    name: "listagem-importacoes-faturamento",
    meta: {
      pageTitle: "Importar XML de Faturamento",
      tipoImportacao: TipoImportacaoEnum.FATURAMENTO_NF_XML,
      permissions: ["Importacoes.resource.index", "Importacoes.faturamento.xml"],
    },
  },
  {
    component: () => import("./templates/importar/importacao.vue"),
    path: "/materiais/importar/razao-contabil",
    name: "listagem-importacoes-razao-contabil",
    meta: {
      pageTitle: "Importar Razão Contábil",
      tipoImportacao: TipoImportacaoEnum.FISCAL_RAZAO_CONTABIL,
      permissions: ["Importacoes.resource.index", "Importacoes.dispendios.razaoContabil"],
    },
  },
  {
    component: () => import("./templates/importar/importacao.vue"),
    path: "/materiais/importar/lalur",
    name: "listagem-importacoes-lalur",
    meta: {
      pageTitle: "Importar LALUR",
      tipoImportacao: TipoImportacaoEnum.LALUR,
      permissions: ["Importacoes.resource.index", "Importacoes.dispendios.lalur"],
    },
  },
  {
    component: () => import("./templates/importar/importacao.vue"),
    path: "/faturamento/importar/anexos-convenios",
    name: "listagem-importacoes-anexos-convenios",
    meta: {
      pageTitle: "Importar Anexo de Convênios",
      tipoImportacao: TipoImportacaoEnum.ANEXO_CONVENIOS,
      permissions: ["Importacoes.resource.index", "Importacoes.faturamento.anexos-convenios"],
    },
  },
  {
    component: () => import("./templates/importar/importacao.vue"),
    path: "/materiais/importar/anexos-contratos",
    name: "listagem-importacoes-anexos-contratos",
    meta: {
      pageTitle: "Importar Contratos de Prestação de Serviços",
      tipoImportacao: TipoImportacaoEnum.ANEXO_CONTRATOS_PRESTACAO_SERVICOS,
      permissions: ["Importacoes.resource.index", "Importacoes.faturamento.anexos-contratos"],
    },
  },
  {
    component: () => import("./templates/importar/importacao.vue"),
    path: "/materiais/importar/anexos-li",
    name: "listagem-importacoes-anexos-li",
    meta: {
      pageTitle: "Importar Anexos de Lei de Informática",
      tipoImportacao: TipoImportacaoEnum.ANEXO_LEI_INFORMATICA,
      permissions: ["Importacoes.resource.index", "Importacoes.faturamento.anexos-li"],
    },
  },
];

/** @type {Route[]} */
const captacao = [
  {
    component: () => import("@/templates/captacao/tipo-rubricas/tipo-rubricas.vue"),
    path: "/captacao/tipo-rubricas",
    name: "captacao-tipo-rubricas",
    meta: {
      pageTitle: 'Tipo rubricas',
      permissions: ["Captacao.tipoRubricas.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/fornecedores/cap-fornecedores.vue"),
    path: "/captacao/fornecedores",
    name: "captacao-fornecedores",
    meta: {
      pageTitle: 'Fornecedores',
      permissions: ["Captacao.fornecedores.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/descricao-dispendios/descricao-dispendios.vue"),
    path: "/captacao/descricao-dispendios",
    name: "descricao-dispendios",
    meta: {
      pageTitle: 'Descrição de Dipêndios',
      permissions: ["Captacao.pareamento.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/demais-dispendios/demais-dispendios.vue"),
    path: "/captacao/demais-dispendios/:id(\\d+)",
    name: "demais-dispendios",
    meta: {
      pageTitle: 'Lançamentos de despesas',
      permissions: ["Captacao.dispendios.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/demais-dispendios/demais-dispendios.form.vue"),
    path: "/captacao/demais-dispendios/form/:id(\\d+)",
    name: "cadastro-demais-dispendios",
    meta: {
      pageTitle: 'Cadastro de despesas',
      permissions: ["Captacao.dispendios.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/demais-dispendios/demais-dispendios.form.vue"),
    path: "/captacao/demais-dispendios/form/:id(\\d+)/:dispendio",
    name: "edicao-demais-dispendios",
    meta: {
      pageTitle: 'Edição de despesa',
      permissions: ["Captacao.dispendios.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/projetos/projetos.captacao.vue"),
    path: "/captacao/projetos",
    name: "projetos-captacao",
    meta: {
      pageTitle: 'Projetos',
      permissions: ["Captacao.projetos.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/projetos/projetos.captacao.form.vue"),
    path: "/captacao/projetos/cadastro",
    name: "projetos-captacao-cadastro",
    meta: {
      pageTitle: 'Cadastro projeto captação',
      permissions: ["Captacao.projetos.index"],
      mode: 'create'
    },
  },
  {
    component: () => import("@/templates/captacao/projetos/projetos.captacao.form.vue"),
    path: "/captacao/projetos/edicao/:id(\\d+)",
    name: "projetos-captacao-edicao",
    meta: {
      pageTitle: 'Edição projeto captação',
      permissions: ["Captacao.projetos.index"],
      mode: 'update'
    },
  },
  {
    component: () => import("@/templates/captacao/timesheets/timesheets.vue"),
    path: "/captacao/timesheets/:id(\\d+)",
    name: "timesheets-captacao",
    meta: {
      pageTitle: 'Timesheets Captação',
      permissions: ["Captacao.timesheets.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/orcamentos/comprovacao-orcamento.vue"),
    path: "/captacao/orcamentos/comprovacao/:id(\\d+)",
    name: "comprovacao-orcamento",
    meta: {
      pageTitle: 'Resumo (PREV/REAL)',
      permissions: ["Captacao.comprovacao.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/remanejamento/remanejamento-rubricas.form.vue"),
    path: "/captacao/remanejamento/:id(\\d+)",
    name: "remanejamento-rubricas",
    meta: {
      pageTitle: 'Remanejamento Rubricas',
      permissions: ["Captacao.comprovacao.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/remanejamento/remanejamento-rubricas.form.vue"),
    path: "/captacao/remanejamento/:id(\\d+)/:rubrica",
    name: "remanejamento-rubricas-edicao",
    meta: {
      pageTitle: 'Remanejamento Rubricas',
      permissions: ["Captacao.comprovacao.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/garantias/garantias.captacao.vue"),
    path: "/captacao/garantias/:id(\\d+)",
    name: "captacao-garantias",
    meta: {
      pageTitle: 'Garantias',
      permissions: ["Captacao.projetos.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/finep/responsaveis-finep.captacao.vue"),
    path: "/captacao/finep/:id(\\d+)",
    name: "captacao-finep",
    meta: {
      pageTitle: 'Responsáveis FINEP',
      permissions: ["Captacao.projetos.index"],
    },
  },
  {
    component: () => import("@/templates/captacao/dispendios/recursoshumanos.vue"),
    path: "/captacao/dispendio-rh/:id(\\d+)",
    name: "captacao-dispendio-rh",
    meta: {
      pageTitle: 'Dispêndio com Recursos Humanos',
      permissions: ["Captacao.projetos.index"],
    },
  }
];

/** @type {Route[]} */
const ativacaoped = [
  {
    component: () => import("@/templates/ativacaoped/businessplan/businessplan.vue"),
    path: "/ativacaoped/projetos",
    name: "ativacaoped-projetos",
    meta: {
      pageTitle: 'Business Plan',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/businessplan/businessplan.form.vue"),
    path: "/ativacaoped/projetos/form",
    name: "ativacaoped-projetos-form",
    meta: {
      pageTitle: 'Inclusão de BP',
      mode: 'create'
    },
  },
  {
    component: () => import("@/templates/ativacaoped/businessplan/businessplan.form.vue"),
    path: "/ativacaoped/projetos/form/:id",
    name: "ativacaoped-projetos-form-edicao",
    meta: {
      pageTitle: 'Edição de BP',
      mode: 'update'
    },
  },
  {
    component: () => import("@/templates/ativacaoped/entregaveis/entregaveis.ativacao.vue"),
    path: "/ativacaoped/entregaveis",
    name: "ativacaoped-entregaveis",
    meta: {
      pageTitle: 'Entregáveis',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/responsaveis/responsaveis.vue"),
    path: "/ativacaoped/responsaveis",
    name: "ativacaoped-responsaveis",
    meta: {
      pageTitle: 'Responsáveis',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/rxo/rxo.ativacao.vue"),
    path: "/ativacaoped/rxo",
    name: "ativacaoped-rxo",
    meta: {
      pageTitle: 'Realizado versus Orçado',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/businessunit/bu.ativacao.vue"),
    path: "/ativacaoped/business-unit",
    name: "ativacaoped-business-unit",
    meta: {
      pageTitle: 'Business Unit',
      permissions: ["AtivacaoPed.businessUnits.index"],
    },
  },
  {
    component: () => import("@/templates/ativacaoped/perfil/perfil.vue"),
    path: "/ativacaoped/perfil",
    name: "ativacaoped-perfil",
    meta: {
      pageTitle: 'Perfil',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/atividades/atividades.vue"),
    path: "/ativacaoped/atividades",
    name: "ativacaoped-atividades",
    meta: {
      pageTitle: 'Atividades',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/nivelhierarquico/nivelhierarquico.vue"),
    path: "/ativacaoped/nivel-hierarquico",
    name: "ativacaoped-nivel-hierarquico",
    meta: {
      pageTitle: 'Nível hierarquico',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/timesheet/timesheet.vue"),
    path: "/ativacaoped/timesheet",
    name: "ativacaoped-timesheet",
    meta: {
      pageTitle: 'Timesheet',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/workflow/acompanhamento.vue"),
    path: "/ativacaoped/workflow/acompanhamento",
    name: "ativacaoped-workflow-acompanhamento",
    meta: {
      pageTitle: 'Acompanhamento',
    },
  },
  {
    component: () => import("@/templates/ativacaoped/workflow/parametrizacao.vue"),
    path: "/ativacaoped/workflow/parametrizacao",
    name: "ativacaoped-workflow-parametrizacao",
    meta: {
      pageTitle: 'Parametrização',
    },
  },
];

/** @type {Route[]} */
const allRoutes = [
  ...base,
  ...galapos,
  ...cadastros,
  ...relatorios,
  ...colaboradores,
  ...projetos,
  ...dispendios,
  ...timesheets,
  ...faturamentos,
  ...importacoes,
  ...captacao,
  ...ativacaoped
];

const unnamedRoutes = allRoutes.filter(({ name }) => !name);

if (unnamedRoutes.length > 0) {
  console.error(`Há ${unnamedRoutes.length} rotas sem nome:\n${unnamedRoutes.map(({ path }) => path).join('\n')}`);
}

export default allRoutes;
